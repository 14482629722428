









// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// CORE
import { Component, Mixins } from 'vue-property-decorator'

// INTERFACES
import { NameValueResource } from '@/store/types'

@Component
export default class CarparkLayout extends Mixins(SystemMixin) {
  // Список табов
  private items = [
    {
      name: 'Автопарк',
      value: 'car',
    },
    {
      name: 'Восстановительный ремонт',
      value: 'repair',
    },
  ] as NameValueResource[]

  private get filteredItems () {
    return this.items.filter(item => {
      return !(this.role === 'mechanic' && item.value === 'repair')
    })
  }
}
